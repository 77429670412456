import React, { Component , Fragment,useEffect } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/Header";
import FooterTwo from "../component/footer/Footer"
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactTwo from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";

import data from '../data.json';
import about from "../assets/images/page-header/ph-1.jpg";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Product Design Manager | Creative Director & Brand Strategist',
        title: "Welcome to <span>Tom's</span> world",
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]



class PersonalPortfolio extends Component{
    constructor(props) {
        super(props);
      }
     
    render(){
        const dataMain      = data.profile;
        const { tabStyle }  = this.props;

        const workData      = data;

     
        let title = 'About Me',
        description = 'As a Product Design Lead, I guide a skilled UX/UI team. With a decade in SaaS design. I expedite design delivery by 10%. Lets innovate together! 🚀✨';
        

       
        return(
            <Fragment> 
                <Helmet pageTitle="Personal Portfolio" />
                
                <HeaderTwo logo="symbol-dark" color="color-black"/>
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex  bg_image bg_image--3" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={about} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                        <TabTwo tabStyle="tab-style--1"
                                            skills      = { dataMain.skills }
                                            awards      = { dataMain.awards}
                                            exp         = { dataMain.experience}
                                            education   = { dataMain.education}
                                            data        = { dataMain }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Work History</h2>
                                    <p>My work history is like a choose-your-own-adventure book. Except most paths lead to free coffee.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                                <Brand branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Projects</h2>
                                        <p>There are many versions of my work out there than Snow White has had costume changes, but sadly most of them have been Frankenstinned into oblivion.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList 
                                styevariation   ="text-center mt--40" 
                                column          ="col-lg-4 col-md-6 col-sm-6 col-12" 
                                item            ="8" 
                                workData        = {workData}
                                />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Portfolio Area */}

                <FooterTwo />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
    
}

export default PersonalPortfolio;